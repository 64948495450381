/**
 *
 *  Color Skin
 *
 *  @package ohkimur_module
 *
 *
 *
 *
 *  ##### Theme Colors Primary
 *  $blue: #21c2f8;
 *  $green: #2ecc71;
 *
 *  ##### Global Pallete
 *  $white: #fff;
 *  $black: #000;
 *  $black-transparent: rgba($black, 0.9);
 *  $light-blue: #f4f8f9;
 *
 *  ##### Primary & Secondary
 *  $primary: $blue; // This is primary theme color
 *  $secondary: #2c3e50;
 *
 *  ##### Global Text Color
 *  $global-text: #848484;
 *
 *  ##### Gray Pallete
 *  $light-gray: #e3e9ed;
 *  $simple-gray: #bdc3c7;
 *  $normal-gray: #464646;
 *  $dark-gray: #202020;
 *
 *
 *
 *
 **/

// Theme Colors
$blue: #21c2f8;
$green: #2ecc71;

// Global Pallete
$white: #fff;
$black: #000;
$black-transparent:  rgba($black, 0.9);
$light-blue: #f4f8f9;

// Primary & Secondary
$primary: $blue;
$secondary: #2c3e50;

// Gray Pallete
$text-gray: #848484;
$light-gray: #e3e9ed;
$simple-gray: #bdc3c7;
$normal-gray: #464646;
$dark-gray: #202020;

// Global Text Color
$global-text: darken($text-gray, 15%);

// Background Color
$background-color: $white;

// Sections Colors
$section-bg: $white;
$section-focus: $light-blue;

// Footer Colors
$footer-color: $text-gray;
$footer-background-color: $dark-gray;

// Sidebar Colors [Widgets]
$sidebar-header: $light-blue;
$sidebar-title: $secondary;

$shadow-color: rgba(39, 36, 36, 0.1);
