/**
 *
 *  Animations Style
 *
 *  @package ohkimur_modules
 *
 **/

// Pulse Animations
@keyframes sera-pulse {
   0% { transform: rotate(45deg)  scale(1.1); }
   50% { transform: rotate(45deg)  scale(1.4); }
   100% { transform: rotate(45deg)  scale(1.1); }
}

// Page Loader Animations
@keyframes sera-loader-in {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes sera-loader-out {
  0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

// Mobile Navigation Trigger Button Animations
@keyframes sera-inM{
  50%{transform:rotate(0deg);}
  100%{transform:rotate(45deg);}
}

@keyframes sera-outM{
  50%{transform:rotate(0deg);}
  100%{transform:rotate(45deg);}
}

@keyframes sera-inT{
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(14px) rotate(0deg); }
  100% { transform: translateY(14px) rotate(135deg); }
}

@keyframes sera-outT{
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(14px) rotate(0deg); }
  100% { transform: translateY(14px) rotate(135deg); }
}

@keyframes sera-inBtm{
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-14px) rotate(0deg); }
  100% { transform: translateY(-14px) rotate(135deg); }
}

@keyframes sera-outBtm{
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-14px) rotate(0deg); }
  100% { transform: translateY(-14px) rotate(135deg); }
}
