/**
 *
 *  Global Classes Style
 *
 *  @package ohkimur_module
 *
 **/

// Typography
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-focus {
  color: $primary;
}

// Alignament Block
.parent {
  position: relative;
}

.child-center-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.child-center-h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.child-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Shapes
.circle {
  border-radius: 120%;
}

// Block Tools
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.affix {
  position: fixed;
}

// Parallax Cover
.parallax {
  overflow: hidden !important;
  background: none !important;
}

.parallax .cover {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	z-index: -999999;

  img {
  	position: absolute;
  	display: block;
    width: auto;
  	min-width: 100%;
  	min-height: 120%;
    max-width: inherit;
    max-height: 140%;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
  }

  .content {
  	position: absolute;
  	display: block;
  	background: rgba(0, 0, 0, 0.7);
  	width: 100%;
  	height: 100%;
  	z-index: 2;
  }

}
