/**
 *
 *  Portfolio Section Style
 *
 *  @package ohkimur_layout
 *
 **/

section.portfolio-section {

  // Filter Menu
  .filter-menu-wrapper {
    margin: 50px 0;

    .filter-menu {
      display: block;
      width: 100%;
      background-color: $light-blue;
      border-radius: 3px;
      letter-spacing: normal;
      text-align: center;

      ul {
        display: inline-block;
        position: relative;
        margin: 0 auto;

        li {
          display: inline-block;
          position: relative;
          padding: 15px;
          cursor: pointer;
          color: $secondary;
          transition: all $transition-links $transition-links-type;

          &:hover {
            color: $primary;
          }

          // Current Menu Item
          &.current {
            color: $primary;

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background: $primary;
            }
          }
        }
      }
    }

  }

  // Portfolio Grid
  .portfolio-grid {
    overflow-x: hidden;

    // Portfolio Grid Item
    .item {
      display: block;
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
      transform: scale(0.95);
      transition: all $transition-links $transition-links-type;

      // Item Image
      .img-wrap {
        position: relative;

        img {
          width: 100%;
          display: block;
        }

      }

      // Item Hover
      &.visible {
        opacity: 0;
        transform: scale(1) translateX(12%);

        &:hover {
          .item-content {
            transform: translateY(0);
          }
        }
      }

      // Item Scroll Reveal
      &.is-showing {
        opacity: 0.3;

        &.visible {
          opacity: 1;
          transform: scale(1) translateX(0);
        }
      }

      // Item Content
      .item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        color: $white;
        background: transparentize($primary, 0.2);

        // Animation
        transform: translateY(100%);
        transition: all $transition-links $transition-links-type;

        h3, h4 {
          text-transform: uppercase;
        }

        h3 {
          font-size: 2rem;
          margin-bottom: 10px;
        }

        // Links
        .links {
          margin-top: 20px;

          ul {
            li {
              display: inline-block;
              font-size: 1.9rem;

              a {
                display: block;
                padding: 5px;
                color: $white;
                background: transparentize($white, 0.8);

                &:hover {
                  background: transparentize($white, 0.6);
                }

              }
            }
          }
        }

      }

    }

    // Overlay Lightbox
    .overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $black-transparent;
      z-index: 99999999999999;

      img {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

  }

}
