/**
 *
 *  Owl Carousel Style
 *
 *  @package ohkimur_modules
 *
 **/

/* display none until init */
.owl-carousel {
  display: none;
  position: relative;

  .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;

    &.autoHeight {
      transition: height 500ms ease-in-out;
    }

    .owl-wrapper {
      display: none;
      position: relative;
      -webkit-transform: translate3d(0px, 0px, 0px);

      /* clearfix */
      &:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
      }
    }

  }

  .owl-item{
    float: left;
  }

  .owl-controls {
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .owl-page,
    .owl-buttons div {
      cursor: pointer;
    }
  }

  /* mouse grab icon */
  .grabbing {
    cursor: move;
  }

  /* fix */
  .owl-wrapper,
  .owl-item{
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
  }

}
