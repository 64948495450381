/**
 *
 *  Buttons Style
 *
 *  @package ohkimur_components
 *
 **/

// Buttons
.button, button {
  display: inline-block;
  width: auto;
  max-width: 100%;
  color: $primary;
  border: 2px solid $primary;
  padding: 0.8em 2em;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;

  &.button-focus {
    color: $white;
    background-color: $primary;
  }

  &:hover,
  &:active {
    color: $white;
    border-color: lighten($primary, 20%);
    background-color: lighten($primary, 20%);
  }

}

// CTA Buttons
.button-cta {
  display: inline-block;
  max-width: 100%;
  color: $white;
  border: 2px solid $white;
  padding: 1em 1.5em;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;

  &.button-focus {
    border-color: $primary;
    background-color: $primary;
  }

  &.button-blue {
    color: $primary;
    border-color: $primary;
  }

  &:hover,
  &:active {
    color: $white;
    border-color: lighten($white, 32%);
    background-color: lighten(#116822, 20%);

  }

}

// Buttons Block
button,
.button,
.button-cta {

  &.button-block {
    display: block;
    width: 100%;
  }

}
